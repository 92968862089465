import React from 'react'
import './GetStarted.css'

const GetStarted = () => {
  return (
    <div className="g-wrapper">
        <div className="paddings innerWidth g-container">
            <div className="flexColCenter inner-container">
                <span className='primaryText'>Get Started with Homyz</span>
                <span className='secondaryText'>SubScribe and find super attractive price quotes from <br />
                 Find your residence soon
                </span>
                <button className='button'>
                    <a href="mailto:gopinakati8978@gmail.com">Get Started</a>
                </button>
            </div>
        </div>
    </div>
  )
}

export default GetStarted