import {
    
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton, // Check the spelling here, it should be AccordionItemButton
    AccordionItemPanel, // Check the spelling here, it should be AccordionItemPanel
     // Check the spelling here, it should be AccordionItemState
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import './Value.css'
import data from '../../utils/accordion'


const Value = () => {
    
  return (
    <section className="v-wrapper">
        <div className="paddings innerWidth flexCenter v-container">
            {/* left side */}
            <div className="v-left">
                <div className="img-container">
                    <img src="./value.png" alt="valueimage" />
                </div>
            </div>

            <div className="flexColStart v-right">
                <span className='orangeText'>Our Value</span>
                <span className='primaryText'>Value We Give to You</span>
                <span className='secondaryText'>We always ready to help by providing the best services for you <br />
                We believe a good blace to live can make your life better
                </span>

            <Accordion className='accordian'
               allowMultipleExpanded={false}
               preExpanded={[0]}
               >{
                data.map((item,i)=>{
                    // const [className,setClassName]= useState(null)

                    return(
                        <AccordionItem className={'accordionItem ${className}'} key={i} uuid={i}>
                           <AccordionItemHeading>
                            <AccordionItemButton className='flexCenter accordionButton'>
                                {/* <AccordionItemState>
                                    {({expanded})=> 
                                    expanded 
                                    ? setClassName("expanded")
                                    : setClassName("collapsed")
                                    }
                                </AccordionItemState> */}
                               <div className="flexCenter icon">{item.icon}</div>
                               <span className="primaryText">
                                {item.heading}
                               </span>
                               <div className="flexCenter icon">
                                <MdOutlineArrowDropDown size={20}/>
                               </div>
                            </AccordionItemButton>
                           </AccordionItemHeading>
                           <AccordionItemPanel>
                            <p className="secondaryText">
                                {item.detail}
                            </p>
                           </AccordionItemPanel>
                        </AccordionItem>
                    )
                })
               }
            </Accordion>
            </div>
        </div>
    </section>
  )
}

export default Value